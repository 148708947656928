<template>
  <div id="home">
    <v-main>
      <v-container class="black--text mt-1 pa-4" id="home-container">
        <v-card shaped elevation="4" class="mt-2 mb-2" id="about">
          <v-card-text>
            <h1>About Us</h1>
            <article>
              <p>
                We are a family owned and operated business, and have lived in
                Pahrump since 2005. We have been servicing Pahrump and the
                surrounding communities since 2006. Our goal is to provide
                excellent service to all of our residential and commercial
                clients. We are trusted by several casinos, hotels, medical
                facilities, fireworks stores, restaurants, and property
                management companies to provide monthly services.
              </p>
            </article>
          </v-card-text>
        </v-card>
        <v-card shaped elevation="4" class="mb-2" id="services">
          <v-card-text>
            <h1>Our Services</h1>
            <article>
              <p>
                We offer residential and commercial services for the
                following....
              </p>
              <ul>
                <li>Carpet Cleaning (vacuum, spray, scrub & extract)</li>
                <li>Carpet Protectant</li>
                <li>VLM (Very Low Moisture) Cleaning</li>
                <li>Tile & Grout Cleaning</li>
                <li>Upholstery Cleaning</li>
                <li>Area Rug Cleaning</li>
                <li>
                  VCT (Vinyl Composite Tile) Floor Cleaning/Stripping/Waxing
                </li>
                <li>RV Carpet and Upholstery Cleaning</li>
                <li>Carpet Stretching and Repairs</li>
                <li>Flood Response/Mitigation</li>
              </ul>
            </article>
          </v-card-text>
        </v-card>

        <v-card shaped elevation="4" class="mt-2 mb-2" id="contact">
          <v-card-text>
            <h1>Contact Us</h1>
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="formIn.firstname"
                      :rules="nameRules"
                      :counter="60"
                      label="First Name"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="formIn.lastname"
                      :rules="nameRules"
                      :counter="60"
                      label="Last Name"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      :counter="120"
                      v-model="formIn.email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formIn.phone"
                      :counter="14"
                      label="Phone"
                      required
                      v-mask="'(###) ###-####'"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formIn.zip"
                      :counter="5"
                      label="Zip"
                      required
                      v-mask="'#####'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="formIn.help"
                      :counter="4000"
                      label="How can we help?"
                      placeholder="Enter your text here."
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions
            ><v-btn class="cmpbk white--text text--white" @click="sendemail"
              >Submit</v-btn
            ></v-card-actions
          >
        </v-card>
        <v-card shaped elevation="4" class="mt-2 mb-2" id="equipment">
          <v-card-text>
            <h1>Equipment</h1>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="comfortaa-pvcc mt-2"
                    >Prochem Everest 650</v-list-item-title
                  >
                  <v-list-item-action-text
                    ><p class="normal-text ma-0">
                      This is the industry’s best selling large-class
                      liquid-cooled truck-mounted carpet cleaning system. It
                      features the Hyundai 4 cyl. power plant and the Gardner
                      Denver TriFlow® Tri-Lobe vacuum blower, and the Everest
                      combines dual-wand capacity with precise temperature
                      control. The Everest is the perfect choice both for carpet
                      cleaning and water restoration as this truckmount features
                      3000 psi solution pressure for the toughest hard surface
                      and pressure washing jobs.
                    </p>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="comfortaa-pvcc mt-2"
                    >Minuteman Floor Machine</v-list-item-title
                  >
                  <v-list-item-action-text
                    ><p class="normal-text ma-0">
                      This 17″ floor machine by Minuteman is ideal for general
                      floor maintenance tasks in areas large or small. The Front
                      Runner series utilizes many types of brushes, pads, and
                      scrubbing heads for cleaning carpet, tile and grout, VCT,
                      stone and many other types of floors. This machine,
                      combined with the appropriate scrub brushes or pads, is
                      often the difference in whether your floors look good … OR
                      IF THEY LOOK GREAT!
                    </p>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="comfortaa-pvcc mt-2">
                    Prochem Titanium 6-Jet Extraction Wand
                  </v-list-item-title>
                  <v-list-item-action-text
                    ><p class="normal-text ma-0">
                      This 13.5-inch titanium wand is the best investment we
                      ever made in a carpet cleaning wand! Made from titanium
                      and stainless steel, this wand is engineered to be 70% the
                      weight of most wands on the market. Its lightweight and
                      insulated grip handle makes this wand easier to control
                      while helping to achieve the best job possible. The vacuum
                      manifold ensures uniform suction across a wider path for
                      thorough cleaning from start to finish. Needless to say,
                      choosing the Prochem Titanium 6-jet wand for our
                      technicians was a very easy decision for us to make.
                      Nothing but the best!!!
                    </p>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="comfortaa-pvcc mt-2">
                    Sapphire Scientific Upholstery Pro-12
                  </v-list-item-title>
                  <v-list-item-action-text
                    ><p class="normal-text ma-0">
                      This amazing upholstery tool effectively cleans a wide
                      range of fabrics and soiled conditions. Precision flow
                      control allows for easy, on-the-fly adjustments and the
                      unique continuous flow design allows us to extract in both
                      directions for the best clean possible.
                    </p>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-card shaped elevation="4" class="mb-2" id="gallery">
          <v-card-text>
            <h1>Photo Gallery</h1>
            <v-row class="">
              <v-col cols="12" md="6">
                <h3>Before</h3>
                <img
                  style="width: 100%; height: auto"
                  src="../images/pvcc/IMG_3567.jpg"
                />
              </v-col>
              <v-col cols="12" md="6">
                <h3>After</h3>
                <img
                  style="width: 100%; height: auto"
                  src="../images/pvcc/IMG_3569.jpg"
                />
              </v-col>
            </v-row>
            <hr />
            <v-row class="">
              <v-col cols="12" md="6">
                <h3>Before</h3>
                <img
                  style="width: 100%; height: auto"
                  src="../images/pvcc/IMG_3570.jpg"
                />
              </v-col>
              <v-col cols="12" md="6">
                <h3>After</h3>
                <img
                  style="width: 100%; height: auto"
                  src="../images/pvcc/IMG_3571.jpg"
                />
              </v-col>
            </v-row>
            <hr />
            <v-row class="">
              <v-col cols="12" md="6">
                <h3>Before</h3>
                <img
                  style="width: 100%; height: auto"
                  src="../images/pvcc/IMG_3593.jpg"
                />
              </v-col>
              <v-col cols="12" md="6">
                <h3>After</h3>
                <img
                  style="width: 100%; height: auto"
                  src="../images/pvcc/IMG_3594.jpg"
                />
              </v-col>
            </v-row>
            <hr />
            <v-row class="">
              <v-col cols="12" md="6">
                <h3>Before and After</h3>
                <img
                  style="width: 100%; height: auto"
                  src="../images/pvcc/IMG_3589.jpg"
                />
              </v-col>
              <v-col cols="12" md="6">
                <h3>Before and After</h3>
                <img
                  style="width: 100%; height: auto"
                  src="../images/pvcc/IMG_3572.jpg"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card shaped elevation="4" class="mt-2 mb-2" id="faqs">
          <v-card-text>
            <h1>FAQs</h1>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="comfortaa-pvcc mt-2"
                    >What are your business hours?</v-list-item-title
                  >
                  <v-list-item-action-text>
                    <p class="normal-text ma-0">
                      We are available by phone seven days a week and will
                      return messages/e-mails as soon as possible. Our regular
                      residential service hours are Monday-Friday, 8 am-5 pm,
                      and our commercial service hours vary, depending on the
                      job.
                    </p>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="comfortaa-pvcc mt-2"
                    >Do you offer free estimates?</v-list-item-title
                  >
                  <v-list-item-action-text>
                    <p class="normal-text ma-0">
                      Yes. We offer free, no obligation estimates. We prefer
                      providing the in person estimate as to make sure we cover
                      all areas of concern at the time of inspection. This will
                      help ensure a more accurate estimate. We do provide phone
                      estimates as well and will be as accurate as possible.
                      Please keep in mind that prices may change upon actual
                      inspection of the job, and things that might need to be
                      addressed further.
                    </p>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="comfortaa-pvcc mt-2"
                    >How long does it take for the carpet to
                    dry?</v-list-item-title
                  >
                  <v-list-item-action-text>
                    <p class="normal-text ma-0">
                      Typically it takes about 4-6 hours, depending on air
                      circulation, humidity, and temperature inside the home or
                      office.
                    </p>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="comfortaa-pvcc mt-2"
                    >Do you move furniture?</v-list-item-title
                  >
                  <v-list-item-action-text>
                    <p class="normal-text ma-0">
                      Yes, upon request we will move easily moveable furniture
                      such as end tables, chairs, night stands. We can move
                      heavier items such as beds, dressers, desks, but it may
                      incur an additional charge. We will not move breakable
                      items such as curio cabinets, etc.
                    </p>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="comfortaa-pvcc mt-2"
                    >How often should I clean my carpet and/or tile and
                    grout?</v-list-item-title
                  >
                  <v-list-item-action-text>
                    <p class="normal-text ma-0">
                      We recommend cleaning yearly. Homes with pets and young
                      children, or high traffic areas we recommend cleaning
                      about every 6 months.
                    </p>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-card shaped elevation="4" class="mt-2 mb-2">
          <v-card-text>
            <h1>Business Info!</h1>
            <article>
              <pre class="comfortaa-pvcc bold sizeit mt-4">
Pahrump Valley Carpet Care, LLC
231 Mondale Ct.
Pahrump, NV 89048
(775) 513-5656
<a href="mailto:pahrumpvalleycarpetcare@gmail.com">PahrumpValleyCarpetCare@gmail.com</a>

</pre>
            </article>
          </v-card-text>
        </v-card>
      </v-container>
      <div style="visibility: hidden; width: 100%; height: 400px"></div>
    </v-main>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";

/* 0 media q */

@media (min-width: 0px) {
  #home-container {
    width: 100%;
    max-width: 1440px;
    height: auto;
    background-color: transparent;
  }
  .bold {
    font-weight: 600 !important;
    font-size: 1.2rem;
  }
  .sizeit {
    font-size: smaller !important;
  }
  p {
    font-weight: 600;
    color: black;
    font-size: 1.1rem;
    margin-top: 10px;
  }
  p.normal-text {
    font-weight: 500;
    color: black;
    font-size: 1rem;
  }
  ul {
    font-weight: 600;
    color: black;
    font-size: 1.1rem;
    margin-top: 10px;
  }
  .alice {
    color: aliceblue;
  }
}

/* 1025 media q */
@media (min-width: 768px) {
  .sizeit {
    font-size: 1rem !important;
  }
}

/* 1025 media q */
@media (min-width: 1025px) {
}

/* 2048 media q */
@media (min-width: 2048px) {
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";

// import auth from "./auth.vue";
//import addedit_diag from "./addedit_diag.vue";
//  const addedit_diag = () => import('./addedit_diag.vue')

export default {
  name: "Home",
  components: {},
  mixins: [bus_common],
  data() {
    // Status, Fname, Lnprivateame, DOB, DIVISION
    return {
      valid: false,
      // formIn_new: {
      //   firstname: "Johntest",
      //   lastname: "Molttest",
      //   email: "jvonmolt@gmail.com",
      //   phone: "(775) 622-9020",
      //   zip: "89436",
      //   help: "Clean ALL my stuff. Rooms the size of an elephant grave yard. Hurry.",
      // },
      formIn_new: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        zip: "",
        help: "",
      },
      formIn: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        zip: "",
        help: "",
      },

      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 61 || "Name must be less than 10 characters",
      ],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  watch: {
    "$store.state.user": function () {
      //keep this
      let self = this;
      this.init();
    },
  },
  methods: {
    /* sendRequest(rowData) {
      console.log(rowData);
    }, */

    sendemail() {
      this.show_loader(true);
      //var formIn = {};
      var formIn = this.mno(this.formIn);
      formIn.apiname = "sendemailnow";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          this.swall_it(
            "Sumbit Status",
            "Your information has been sent.",
            "success",
            null,
            null
          );
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saveing", msg, "error", null, null);
        });
    },
    scrollToElement(elementId) {
      // Get the element by its ID
      var element = document.getElementById(elementId);

      // Check if the element exists
      if (element) {
        // Scroll to the element
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        console.error("Element not found: " + elementId);
      }
    },
    sendRequest() {
      alert("This button does nothing yet.");
    },
    message1() {
      alert("when clicked this ");
    },

    init(self) {
      this.formIn = this.mno(this.formIn_new);
      this.show_loader(false);
    },
  },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      this.init(self);
    });
  },
};
</script>
