<template>
  <!-- ON LARGE AND MEDIUM SCREENS ONLY -->

  <v-container>
    <!-- for mobile -->
    <div
      class="d-flex d-sm-none"
      style="
        width: 100%;
        height: 114px;
        background-color: #004a28;
        position: fixed;
        z-index: 100;
      "
    >
      <div
        id="menu-bar"
        app
        class="d-flex flex-row justify-space-between white--text img2 img3"
        color="black"
        elevation="4"
        style="height: 114px; width: 100%"
      >
        <v-spacer></v-spacer>
        <div>
          <img class="img1" src="../images/pvcc/logo250.png" />
        </div>
        <v-spacer></v-spacer>
        <div
          @click="show_nav"
          class="cmpbk text--white"
          style="color: white; width: 40px; height: 40px"
        >
          <v-icon x-large>$menu</v-icon>
        </div>

        <!-- <img class="img2" src="../images/hss/logos/new_logo_inverted.png" /> -->
        <!-- <img class="img3" src="../images/hss/logos/new_logo.jpg" /> -->
      </div>
    </div>
    <v-navigation-drawer
      style="z-index: 200"
      class="black d-flex d-sm-none"
      v-model="drawer"
      right
      fixed
      temporary
    >
      <v-list nav dense class="whitesmoke">
        <v-list-item-group v-model="group" active-class="white">
          <v-list-item @click="scrollToElementInContainer_mobile('home')">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollToElementInContainer_mobile('about')">
            <v-list-item-title>About Us</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollToElementInContainer_mobile('services')">
            <v-list-item-title>Our Services</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollToElementInContainer_mobile('contact')">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollToElementInContainer_mobile('equipment')">
            <v-list-item-title>Equipment</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollToElementInContainer_mobile('gallery')">
            <v-list-item-title>Gallery</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollToElementInContainer_mobile('faqs')">
            <v-list-item-title>FAQs</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div id="hero" class="d-flex d-sm-none"></div>

    <!-- for desktop -->
    <div class="d-none d-sm-flex" style="width: 100%">
      <v-app-bar
        id="menu-bar"
        app
        class="d-flex flex-row justify-space-between white--text img2 img3"
        color="black"
        elevation="4"
        style="height: 114px"
      >
        <img class="img1" src="../images/pvcc/logobigger.png" />
        <!-- <img class="img2" src="../images/hss/logos/new_logo_inverted.png" /> -->
        <!-- <img class="img3" src="../images/hss/logos/new_logo.jpg" /> -->
        <a href="#" @click="scrollToElementInContainer('home')">Home</a>

        <a href="#" @click="scrollToElementInContainer('about')">About Us</a>

        <a href="#" @click="scrollToElementInContainer('services')"
          >Our Services</a
        >

        <a href="#" @click="scrollToElementInContainer('contact')"
          >Contact Us</a
        >
        <a href="#" @click="scrollToElementInContainer('equipment')"
          >Equipment</a
        >
        <a href="#" @click="scrollToElementInContainer('gallery')">Gallery</a>

        <a href="#" @click="scrollToElementInContainer('faqs')">FAQs</a>
      </v-app-bar>
      <div
        style="
          width: 100%;
          max-width: 1440px;
          margin: 0 auto;
          border-radius: 5px;
          margin-top: 115px;
        "
        class="d-flex flex-row justify-center"
        id="hero_lrg"
      >
        <img style="width: 1440px" src="../images/pvcc/missy_hero.png" />
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
/* 0 media q */
@media (min-width: 0px) {
  .v-icon.v-icon {
    color: white;
  }
  #hero {
    background-image: url("../images/pvcc/missy_mobile_hero.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 357px;
    margin-top: 114px;
  }

  #menu-bar img.img1 {
    width: 240px;
    padding: 5px;
  }
  .alice {
    color: aliceblue;
  }

  .mob-nav .alice {
    color: aliceblue;
  }

  .v-navigation-drawer {
    background-color: #344e6b;
  }

  .ph-btn,
  .mail-btn {
    width: 42%;
  }

  .sm-toolbar v-toolbar__content {
    flex-direction: comlumn !important;
  }

  .v-size--default {
    font-size: 0.65rem;
  }

  .transparent-banner {
    background-color: transparent;
  }

  .theme--light.v-toolbar.v-sheet {
    background-color: transparent;
  }
}

/* 768 media q */
@media (min-width: 768px) {
  #menu-bar .v-toolbar__content {
    width: 100%;
    max-width: 1440px;
    height: 114px !important;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;

    background-color: #004a28;
  }
}

/* 1025 media q */
@media (min-width: 1025px) {
}

/* 2048 media q */
@media (min-width: 2048px) {
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
export default {
  name: "banner",
  mixins: [bus_common],
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
  methods: {
    scrollToElementInContainer_mobile(targetId) {
      this.drawer = false;
      // Get the container and target elements by their IDs
      let containerId = "vapp";
      var container = document.getElementById(containerId);
      var target = document.getElementById(targetId);

      container.scrollTo(0, 0);
      if (targetId == "home") {
        return true;
      }

      // Check if both elements exist
      if (container && target) {
        // Calculate the offset of the target element relative to the container
        var offsetTop = target.offsetTop - container.offsetTop;

        // Scroll the container to the target element
        container.scrollTo(0, offsetTop + 360);
      } else {
        console.error("Container or target element not found");
      }
    },
    scrollToElementInContainer(targetId) {
      // Get the container and target elements by their IDs
      let containerId = "vapp";
      var container = document.getElementById(containerId);
      var target = document.getElementById(targetId);

      container.scrollTo(0, 0);
      if (targetId == "home") {
        return true;
      }

      // Check if both elements exist
      if (container && target) {
        // Calculate the offset of the target element relative to the container
        var offsetTop = target.offsetTop - container.offsetTop;

        // Scroll the container to the target element
        container.scrollTo(0, offsetTop + 666);
      } else {
        console.error("Container or target element not found");
      }
    },
    scrollToTargetAdjusted(elementId) {
      this.mrbreakpoint("scroll");
      var element = document.getElementById(elementId);
      var headerOffset = 45;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    scrollToElement(elementId) {
      // Get the element by its ID
      var element = document.getElementById(elementId);

      // Check if the element exists
      if (element) {
        // Scroll to the element
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        console.error("Element not found: " + elementId);
      }
    },
    message1() {
      alert("This button does nothing yet.");
    },
    message2() {
      alert("This button will take the user to the appropriate page.");
    },
    show_nav() {
      this.drawer = true;
    },
    init() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
